// composables
import type { Component } from 'vue'
import type { RouteLocationNormalized, RouteLocationNormalizedLoaded, RouteRecordRaw } from 'vue-router'
import { sanitizeUrl } from '@braintree/sanitize-url'

import { createClient } from 'villus'
import {

  RouterView,
} from 'vue-router'
import { isUserLoggedInGuard } from '~/composables/user'
import { MetricType, UsersType } from '~/types/graphql'
import { canUseOrgMetricsFully } from '~/utils/permission'

import { getOrganizationChooseBillingUrl } from '~/utils/urls'
import { PAGE_SIZE } from '~/utils/utils'
import { villusClientOptions } from '~/utils/villus'
import { flagGuard } from './composables/flag'
import { GetUserAndCheckOrgFeatureDocument } from './routes.generated'

const NotFound = async (): Promise<Component> => import('~/pages/NotFound.vue')
async function DeploymentPage(): Promise<Component> {
  return import('~/pages/deployment/_Page.vue')
}

// deploy details pages
async function DeployDetailsPage(): Promise<Component> {
  return import('~/pages/deploy_details/_Page.vue')
}
async function DeployDetailsTimelineTab(): Promise<Component> {
  return import('~/pages/deploy_details/TimelineTab.vue')
}
async function DeployDetailsApprovalsTab(): Promise<Component> {
  return import('~/pages/deploy_details/ApprovalsTab.vue')
}
async function DeployDetailsLinksTab(): Promise<Component> {
  return import('~/pages/deploy_details/LinksTab.vue')
}
async function DeployDetailsPullRequestsTab(): Promise<Component> {
  return import('~/pages/deploy_details/PullRequestsTab.vue')
}
async function DeployDetailsCommitsTab(): Promise<Component> {
  return import('~/pages/deploy_details/CommitsTab.vue')
}
async function DeployDetailsIssuesTab(): Promise<Component> {
  return import('~/pages/deploy_details/IssuesTab.vue')
}
async function DeployDetailsFilesTab(): Promise<Component> {
  return import('~/pages/deploy_details/FilesTab.vue')
}
async function DeployDetailsBuildsTab(): Promise<Component> {
  return import('~/pages/deploy_details/BuildsTab.vue')
}
async function DeployDetailsImpactHistoryTab(): Promise<Component> {
  return import('~/pages/deploy_details/ImpactHistoryTab.vue')
}
async function DeployDetailsAuthorsTab(): Promise<Component> {
  return import('~/pages/deploy_details/AuthorsTab.vue')
}
async function DeployDetailsTeamsTab(): Promise<Component> {
  return import('~/pages/deploy_details/TeamsTab.vue')
}
async function DeployDetailsActionsTab(): Promise<Component> {
  return import('~/pages/deploy_details/ActionsTab.vue')
}
async function DeployDetailsRelatedDeploysTab(): Promise<Component> {
  return import('~/pages/deploy_details/RelatedDeploysTab.vue')
}
async function DeployDetailsRolledBackDeploysTab(): Promise<Component> {
  return import('~/pages/deploy_details/RolledBackDeploysTab.vue')
}
async function MoveCodeDeploymentPage(): Promise<Component> {
  return import('~/pages/deploy_details/MoveCodeDeploymentPage.vue')
}

async function ActionsHistoryPage(): Promise<Component> {
  return import('~/pages/actions_history/_Page.vue')
}

async function ApprovalWizardPage(): Promise<Component> {
  return import('~/pages/approval_wizard/_Page.vue')
}

async function ImpactDetailsPage(): Promise<Component> {
  return import('~/pages/impact_details/_Page.vue')
}
async function ImpactOverviewPage(): Promise<Component> {
  return import('~/pages/impact_overview/_Page.vue')
}

async function SearchPage(): Promise<Component> {
  return import('~/pages/search/_Page.vue')
}

async function TrendsPage(): Promise<Component> {
  return import('~/pages/trends/_Page.vue')
}
async function TrendsBreakdownPage(): Promise<Component> {
  return import('~/pages/trends/_BreakdownPage.vue')
}
async function ComparisonPage(): Promise<Component> {
  return import('~/pages/comparison/_Page.vue')
}

async function ProjectDashboardPage(): Promise<Component> {
  return import('~/pages/project_dashboard/_Page.vue')
}

// project & team metrics pages
async function ProjectMetricsPage(): Promise<Component> {
  return import('~/pages/project_metrics/_Page.vue')
}
async function TeamMetricsPage(): Promise<Component> {
  return import('~/pages/team_metrics/_Page.vue')
}
async function LeadTimeChart(): Promise<Component> {
  return import('~/components/metrics/LeadTimeChart.vue')
}
async function DeployFrequencyChart(): Promise<Component> {
  return import('~/components/metrics/DeployFrequencyChart.vue')
}
async function FailureRateChart(): Promise<Component> {
  return import('~/components/metrics/FailureRateChart.vue')
}
async function MTTRChart(): Promise<Component> {
  return import('~/components/metrics/MTTRChart.vue')
}

async function ProjectMetricsInsightPage(): Promise<Component> {
  return import('~/pages/project_metrics_insight_details/_Page.vue')
}

async function OrgStatusPage(): Promise<Component> {
  return import('~/pages/org_status/_Page.vue')
}

// account pages
async function LoginPage(): Promise<Component> {
  return import('~/pages/account/LoginPage.vue')
}
async function SignupPage(): Promise<Component> {
  return import('~/pages/account/SignupPage.vue')
}
async function UserSetupPage(): Promise<Component> {
  return import('~/pages/account/UserSetupPage.vue')
}
async function PasswordResetPage(): Promise<Component> {
  return import('~/pages/account/PasswordResetPage.vue')
}
async function PasswordResetConfirmPage(): Promise<Component> {
  return import('~/pages/account/PasswordResetConfirmPage.vue')
}

// account settings pages
async function AccountSettingsPage(): Promise<Component> {
  return import('~/pages/account/settings/_Page.vue')
}
async function EmailSubscriptionsTab(): Promise<Component> {
  return import('~/pages/account/settings/tabs/EmailSubscriptions.vue')
}
async function AccountTab(): Promise<Component> {
  return import('~/pages/account/settings/tabs/Account.vue')
}
async function PersonalTokensTab(): Promise<Component> {
  return import('~/pages/account/settings/tabs/PersonalTokens.vue')
}

// setup pages
async function NewProjectPage(): Promise<Component> {
  return import('~/pages/setup/NewProjectPage.vue')
}
async function CodeProviderSelectionPage(): Promise<Component> {
  return import('~/pages/setup/CodeProviderSelectionPage.vue')
}
async function RepositoryConfigurationPage(): Promise<Component> {
  return import('~/pages/setup/RepositoryConfigurationPage.vue')
}
async function DeployDetectionPage(): Promise<Component> {
  return import('~/pages/setup/DeployDetectionPage.vue')
}
async function DeployDetectionConfirmPage(): Promise<Component> {
  return import('~/pages/setup/DeployDetectionConfirmPage.vue')
}
async function ImpactSourceFormPage(): Promise<Component> {
  return import('~/pages/setup/ImpactSourceFormPage.vue')
}
async function CreateEditImpactSourceForm(): Promise<Component> {
  return import('~/pages/setup/CreateEditImpactSourceForm.vue')
}
async function FeatureFlagProviderSelectionPage(): Promise<Component> {
  return import('~/pages/setup/FeatureFlagProviderSelectionPage.vue')
}
async function FeatureFlagDeploymentPage(): Promise<Component> {
  return import('~/pages/setup/FeatureFlagDeploymentPage.vue')
}
async function AddManualDeploy(): Promise<Component> {
  return import('~/pages/setup/AddManualDeploy.vue')
}

// organization settings pages
async function OrganizationSettingsPage(): Promise<Component> {
  return import('~/pages/organization_settings/_Page.vue')
}
async function OrganizationMembers(): Promise<Component> {
  return import('~/pages/organization_settings/Members.vue')
}
async function OrganizationMembersList(): Promise<Component> {
  return import('~/pages/organization_settings/MembersList.vue')
}
async function OrganizationMembersInvite(): Promise<Component> {
  return import('~/pages/organization_settings/MembersInvite.vue')
}
async function OrganizationMembersExternalUserManagement(): Promise<Component> {
  return import('~/pages/organization_settings/ExternalUserManagement.vue')
}
async function OrganizationDetails(): Promise<Component> {
  return import('~/pages/organization_settings/Details.vue')
}
async function OrganizationAuthentication(): Promise<Component> {
  return import('~/pages/organization_settings/Authentication.vue')
}
async function OrganizationAccessTokens(): Promise<Component> {
  return import('~/pages/organization_settings/AccessTokens.vue')
}
async function OrganizationTeams(): Promise<Component> {
  return import('~/pages/organization_settings/Teams.vue')
}
async function OrganizationProjects(): Promise<Component> {
  return import('~/pages/organization_settings/Projects.vue')
}
async function OrganizationLabels(): Promise<Component> {
  return import('~/pages/organization_settings/Labels.vue')
}

// team settings focused pages
async function TeamDetailsPage(): Promise<Component> {
  return import('~/pages/team_details/_Page.vue')
}
async function TeamMembersPage(): Promise<Component> {
  return import('~/pages/team_members/_Page.vue')
}

// project settings pages
async function ProjectSettingsPage(): Promise<Component> {
  return import('~/pages/project_settings/_Page.vue')
}
async function ProjectDetails(): Promise<Component> {
  return import('~/pages/project_settings/Details.vue')
}
async function ProjectSlackNotifications(): Promise<Component> {
  return import('~/pages/project_settings/SlackNotifications.vue')
}
async function ProjectEnvironments(): Promise<Component> {
  return import('~/pages/project_settings/Environments.vue')
}
async function ProjectImpactSources(): Promise<Component> {
  return import('~/pages/project_settings/ImpactSources.vue')
}
async function ProjectLabels(): Promise<Component> {
  return import('~/pages/project_settings/Labels.vue')
}
async function ProjectFeatureFlags(): Promise<Component> {
  return import('~/pages/project_settings/FeatureFlags.vue')
}
async function ProjectCodeDeployments(): Promise<Component> {
  return import('~/pages/project_settings/CodeDeployments.vue')
}

// new project form
async function ProjectCreatePage(): Promise<Component> {
  return import('~/pages/project_create/_Page.vue')
}

// integrations page
async function IntegrationsPage(): Promise<Component> {
  return import('~/pages/integrations/_Page.vue')
}

// work in progress pages
async function ProjectWIPPage(): Promise<Component> {
  return import('~/pages/project_wip/_Page.vue')
}
async function TeamWIPPage(): Promise<Component> {
  return import('~/pages/team_wip/_Page.vue')
}

// breakdown issues page
async function BreakdownIssuesPage(): Promise<Component> {
  return import('~/pages/breakdown_issues/_Page.vue')
}

// goals
async function ProjectGoalsPage(): Promise<Component> {
  return import('~/pages/project_goals/_Page.vue')
}
async function TeamGoalsPage(): Promise<Component> {
  return import('~/pages/team_goals/_Page.vue')
}

// drift details pages
async function DriftDetailsPage(): Promise<Component> {
  return import('~/pages/drift_details/_Page.vue')
}
async function DeploysTab(): Promise<Component> {
  return import('~/pages/drift_details/DeploysTab.vue')
}
async function CommitsTab(): Promise<Component> {
  return import('~/pages/drift_details/CommitsTab.vue')
}
async function PullRequestsTab(): Promise<Component> {
  return import('~/pages/drift_details/PullRequestsTab.vue')
}
async function IssuesTab(): Promise<Component> {
  return import('~/pages/drift_details/IssuesTab.vue')
}

// automations
async function MarketplacePage(): Promise<Component> {
  return import('~/pages/automations/marketplace/_Page.vue')
}
async function MarketplaceToolDetails(): Promise<Component> {
  return import('~/pages/automations/marketplace/MarketplaceToolDetails.vue')
}
async function ProjectToolDetails(): Promise<Component> {
  return import('~/pages/automations/project/ProjectToolDetails.vue')
}
async function TeamToolDetails(): Promise<Component> {
  return import('~/pages/automations/team/TeamToolDetails.vue')
}
async function ToolInstall(): Promise<Component> {
  return import('~/pages/automations/tools/ToolInstall.vue')
}
async function ProjectToolEdit(): Promise<Component> {
  return import('~/pages/automations/project/ProjectToolEdit.vue')
}
async function TeamToolEdit(): Promise<Component> {
  return import('~/pages/automations/team/TeamToolEdit.vue')
}
async function ProjectAutomationsPage(): Promise<Component> {
  return import('~/pages/automations/project/_Page.vue')
}
async function TeamAutomationsPage(): Promise<Component> {
  return import('~/pages/automations/team/_Page.vue')
}
async function PullRequestChecksPage(): Promise<Component> {
  return import('~/pages/automations/pull_request_checks/_Page.vue')
}

async function BrokenConfigurationsPage(): Promise<Component> {
  return import('~/pages/broken_configurations/_Page.vue')
}

function routeProps(queryPropDefaults = {}) {
  return (route: RouteLocationNormalized) => ({
    query: { ...queryPropDefaults, ...route.query },
  })
}

export const ROUTES_THAT_REQUIRE_ENV = [
  'deployment',
  'projectDashboard',
  'impactDetails',
  'impactOverview',
  // project metrics tabs
  MetricType.Frequency,
  MetricType.LeadTime,
  MetricType.FailureRate,
  MetricType.Mttr,
  'projectWIP',
  'actionsHistory',
  'projectGoals',
]

// Guard checks for various pages
const client = createClient(villusClientOptions)
async function canChangeOrgConfigGuard(to: RouteLocationNormalized) {
  const { data } = await client.executeQuery({
    query: GetUserAndCheckOrgFeatureDocument,
    variables: { orgSlug: to.params.orgSlug.toString() },
  })
  return data?.user?.permissions.canChangeOrgConfig ?? false
}
async function impactOverviewPageGuard(to: RouteLocationNormalized) {
  const { data } = await client.executeQuery({
    query: GetUserAndCheckOrgFeatureDocument,
    variables: { orgSlug: to.params.orgSlug.toString() },
  })
  const mayPass = data?.user?.isStaff ?? false

  return mayPass || { name: 'projectMetrics', params: to.params }
}
async function comparePageGuard(to: RouteLocationNormalized) {
  const { data } = await client.executeQuery({
    query: GetUserAndCheckOrgFeatureDocument,
    variables: { orgSlug: to.params.orgSlug.toString() },
  })
  const hasFlagEnabled = await flagGuard(to, 'ORGANIZATION_METRICS', client)

  if (!canUseOrgMetricsFully(data, hasFlagEnabled)) {
    window.location.replace(
      getOrganizationChooseBillingUrl(to.params.orgSlug.toString()),
    )
  }

  return true
}
async function orgMembersExternalUserManagementPageGuard(to: RouteLocationNormalized) {
  const { data } = await client.executeQuery({
    query: GetUserAndCheckOrgFeatureDocument,
    variables: { orgSlug: to.params.orgSlug.toString() },
  })
  if (!data?.user)
    return false

  const currentUserId = data.user.id ? data.user.id : '-1'
  const userId = to.params.userId.toString()

  // admin can see any org user
  if (data.user.permissions.canChangeOrg)
    return true

  // not admin can only see their identities
  return userId === currentUserId || { name: 'notFound' }
}
const orgLevelPageNames = [
  'search',
  'orgStatus',
  'trendsBreakdown',
  'comparison',
]
export function isOrgOrAccountPage(route: RouteLocationNormalizedLoaded) {
  return orgLevelPageNames.includes(route.name?.toString() ?? '')
    || route.path.startsWith('/organization/')
    || route.path.startsWith('/account/')
}

export const externalLinks = {
  public_marketplace: 'https://marketplace.sleuth.io/',
}

export const routes: RouteRecordRaw[] = [
  {
    path: '/accounts/login/',
    component: LoginPage,
    props: route => ({
      next: sanitizeUrl(route.query.next?.toString() ?? '/'),
      invitationCode: route.query.invitation_code,
      pulseLogin: route.query.pulse_login,
    }),
    name: 'login',
    meta: { noProjectOrEnvironment: true, hideTopNav: true },
  },
  {
    path: '/account/signup/',
    component: SignupPage,
    props: route => ({
      next: sanitizeUrl(route.query.next?.toString() ?? '/'),
      invitationCode: route.query.invitation_code,
    }),
    name: 'signup',
    meta: { noProjectOrEnvironment: true, hideTopNav: true },
  },
  {
    path: '/account/setup/',
    component: UserSetupPage,
    name: 'userSetup',
    meta: { noProjectOrEnvironment: true, hideTopNav: true },
  },
  {
    path: '/accounts/password_reset/:email?',
    component: PasswordResetPage,
    props: route => ({
      initEmail: route.params.email,
    }),
    name: 'passwordReset',
    meta: { noProjectOrEnvironment: true, hideTopNav: true },
  },
  {
    path: '/accounts/reset/:uid/:token',
    component: PasswordResetConfirmPage,
    name: 'passwordResetConfirm',
    meta: { noProjectOrEnvironment: true, hideTopNav: true },
  },
  {
    path: '/account',
    component: AccountSettingsPage,
    name: 'accountSettings',
    children: [
      {
        path: 'manage_account',
        component: AccountTab,
        name: 'manageAccount',
        props: true,
      },
      {
        path: 'personal_tokens',
        component: PersonalTokensTab,
        name: 'personalTokens',
        props: true,
      },
      {
        path: 'unsubscribe',
        component: EmailSubscriptionsTab,
        name: 'emailSubscriptions',
        props: true,
      },
    ],
  },
  {
    path: '/:orgSlug/setup',
    name: 'setupProjectWizard',
    component: RouterView,
    redirect: () => ({ name: 'setupProjectWizardName' }),
    children: [
      {
        path: '1_project_name',
        component: NewProjectPage,
        props: true,
        name: 'setupProjectWizardName',
        meta: { noProjectOrEnvironment: true, hideTopNav: true },
      },
      {
        path: ':projectSlug/2_repo_configuration/:deploymentSlug?',
        component: RepositoryConfigurationPage,
        props: true,
        name: 'setupProjectWizardRepoConfiguration',
        // must use `noProjectOrEnvironment` here in order to read project slug from URL param instead of context!
        meta: { noProjectOrEnvironment: true, hideTopNav: true },
      },
      {
        path: ':projectSlug/:deploymentSlug',
        component: RouterView,
        redirect: () => ({ name: 'setupProjectWizardDeployDetection' }),
        children: [
          {
            path: '3_deploy_detection',
            component: DeployDetectionPage,
            props: true,
            name: 'setupProjectWizardDeployDetection',
            meta: { noProjectOrEnvironment: true, hideTopNav: true },
          },
          {
            path: '3_deploy_detection/confirm',
            component: DeployDetectionConfirmPage,
            props: true,
            name: 'setupProjectWizardDeployDetectionConfirm',
            meta: { noProjectOrEnvironment: true, hideTopNav: true },
          },
          {
            path: '4_impact_sources',
            component: ImpactSourceFormPage,
            props: route => ({
              orgSlug: route.params.orgSlug,
              projectSlug: route.params.projectSlug,
              deploymentSlug: route.params.deploymentSlug,
              isSetupWizard: true,
            }),
            name: 'setupProjectWizardImpactSources',
            meta: { noProjectOrEnvironment: true, hideTopNav: true },
          },
        ],
      },
    ],
  },
  {
    path: '/:orgSlug/deployments/:deploymentSlug',
    component: DeploymentPage,
    name: 'deployment',
    props: true,
  },
  {
    path: '/:orgSlug/deployments/:deploymentSlug/:deploySlug',
    component: DeployDetailsPage,
    name: 'deploy',
    props: true,
    children: [
      {
        path: 'timeline',
        component: DeployDetailsTimelineTab,
        name: 'deployDetailsTimelineTab',
        props: true, // no pagination so we don't need routeProps
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'approvals',
        component: DeployDetailsApprovalsTab,
        name: 'deployDetailsApprovalsTab',
        props: routeProps(),
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'links',
        component: DeployDetailsLinksTab,
        name: 'deployDetailsLinksTab',
        props: routeProps(),
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'pull_requests',
        component: DeployDetailsPullRequestsTab,
        name: 'deployDetailsPrsTab',
        props: routeProps(),
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'commits',
        component: DeployDetailsCommitsTab,
        name: 'deployDetailsCommitsTab',
        props: routeProps(),
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'issues',
        component: DeployDetailsIssuesTab,
        name: 'deployDetailsIssuesTab',
        props: routeProps(),
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'files',
        component: DeployDetailsFilesTab,
        name: 'deployDetailsFilesTab',
        props: routeProps(),
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'builds',
        component: DeployDetailsBuildsTab,
        name: 'deployDetailsBuildsTab',
        props: routeProps(),
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'impact',
        component: DeployDetailsImpactHistoryTab,
        name: 'deployDetailsImpactHistoryTab',
        props: (route) => {
          if (
            route.query.first === undefined
            && route.query.last === undefined
          ) {
            return { query: { first: PAGE_SIZE, ...route.query } }
          }
          else {
            return { query: route.query }
          }
        },
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'authors',
        component: DeployDetailsAuthorsTab,
        name: 'deployDetailsAuthorsTab',
        props: routeProps(),
        meta: { fastLoad: true },
      },
      {
        path: 'teams',
        component: DeployDetailsTeamsTab,
        name: 'deployDetailsTeamsTab',
        props: routeProps(),
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'actions',
        component: DeployDetailsActionsTab,
        name: 'deployDetailsActionsTab',
        props: routeProps({ page: 1, status: 'SUCCESS' }),
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'related-deploys',
        component: DeployDetailsRelatedDeploysTab,
        name: 'deployDetailsRelatedDeploysTab',
        props: routeProps(),
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'rolled-back-deploys',
        component: DeployDetailsRolledBackDeploysTab,
        name: 'deployDetailsRolledBackDeploysTab',
        props: routeProps(),
        meta: { preserveScroll: true, fastLoad: true },
      },
    ],
  },
  {
    path: '/:orgSlug/deployments/:deploymentSlug/actions/history',
    component: ActionsHistoryPage,
    props: route => ({
      orgSlug: route.params.orgSlug,
      deploymentSlug: route.params.deploymentSlug,
      queryParams: route.query,
    }),
    name: 'actionsHistory',
  },
  {
    path: '/:orgSlug/deployments/:deploymentSlug/actions/wizard',
    component: ApprovalWizardPage,
    props: true,
    name: 'approvalWizard',
    meta: { hideTopNav: true },
  },
  {
    path: '/:orgSlug/deployments/:deploymentSlug/move',
    component: MoveCodeDeploymentPage,
    name: 'moveCodeDeployment',
    props: true,
    meta: { hideTopNav: true },
  },
  {
    path: '/:orgSlug/deployments/:deploymentSlug/drift',
    component: DriftDetailsPage,
    name: 'driftDetails',
    props: true,
    meta: { hideTopNav: true },
    redirect: () => ({ name: 'driftDetailsDeploys' }),
    children: [
      {
        path: 'deploys',
        component: DeploysTab,
        name: 'driftDetailsDeploys',
        props: true,
      },
      {
        path: 'pull-requests',
        component: PullRequestsTab,
        name: 'driftDetailsPullRequests',
        props: true,
      },
      {
        path: 'issues',
        component: IssuesTab,
        name: 'driftDetailsIssues',
        props: true,
      },
      {
        path: 'commits',
        component: CommitsTab,
        name: 'driftDetailsCommits',
        props: true,
      },
    ],
  },
  {
    path: '/:orgSlug/:projectSlug/impacts/:impactSourceSlug',
    component: ImpactDetailsPage,
    props: true,
    name: 'impactDetails',
  },
  {
    path: '/:orgSlug/:projectSlug/impact_overview',
    component: ImpactOverviewPage,
    props: true,
    name: 'impactOverview',
    beforeEnter: impactOverviewPageGuard,
  },
  {
    path: '/:orgSlug/:projectSlug/add_code_deployment',
    name: 'addCodeDeployment',
    component: RouterView,
    redirect: () => ({ name: 'addCodeDeploymentProviderSelection' }),
    children: [
      {
        path: 'provider_selection',
        component: CodeProviderSelectionPage,
        props: true,
        name: 'addCodeDeploymentProviderSelection',
        meta: { hideTopNav: true },
      },
      {
        path: 'repo_configuration/:deploymentSlug?',
        component: RepositoryConfigurationPage,
        props: true,
        name: 'addCodeDeploymentRepoConfiguration',
        meta: { hideTopNav: true },
      },
      {
        path: ':deploymentSlug/deploy_detection',
        component: DeployDetectionPage,
        props: true,
        name: 'addCodeDeploymentDeployDetection',
        meta: { hideTopNav: true },
      },
      {
        path: ':deploymentSlug/deploy_detection/confirm',
        component: DeployDetectionConfirmPage,
        props: true,
        name: 'addCodeDeploymentDeployDetectionConfirm',
        meta: { hideTopNav: true },
      },
    ],
  },
  {
    path: '/:orgSlug/:projectSlug/add_manual_deploy',
    name: 'addManualDeploy',
    component: AddManualDeploy,
    meta: { hideTopNav: true },
    beforeEnter: canChangeOrgConfigGuard,
  },
  {
    path: '/:orgSlug/:projectSlug/add_ff_deployment',
    name: 'addFeatureFlagDeployment',
    component: RouterView,
    redirect: () => ({ name: 'addFeatureFlagDeploymentProviderSelection' }),
    children: [
      {
        path: 'provider_selection',
        component: FeatureFlagProviderSelectionPage,
        props: true,
        name: 'addFeatureFlagDeploymentProviderSelection',
        meta: { hideTopNav: true },
      },
      {
        path: 'details',
        component: FeatureFlagDeploymentPage,
        props: true,
        name: 'addFeatureFlagDeploymentDetails',
        beforeEnter: to =>
          to.query.provider
            ? true
            : {
                name: 'addFeatureFlagDeploymentProviderSelection',
                params: to.params,
              },
        meta: { hideTopNav: true },
      },
    ],
  },
  {
    path: '/:orgSlug/:projectSlug/edit_ff_deployment/:deploymentSlug',
    component: FeatureFlagDeploymentPage,
    props: true,
    name: 'editFeatureFlagDeployment',
    meta: { hideTopNav: true },
  },
  {
    path: '/:orgSlug/:projectSlug/edit_code_deployment/:deploymentSlug',
    name: 'editCodeDeployment',
    component: RouterView,
    redirect: () => ({ name: 'editCodeDeploymentRepoConfiguration' }),
    children: [
      {
        path: 'repo_configuration',
        component: RepositoryConfigurationPage,
        props: true,
        name: 'editCodeDeploymentRepoConfiguration',
        meta: { hideTopNav: true },
        beforeEnter: canChangeOrgConfigGuard,
      },
      {
        path: 'deploy_detection',
        component: DeployDetectionPage,
        props: true,
        name: 'editCodeDeploymentDeployDetection',
        meta: { hideTopNav: true },
        beforeEnter: canChangeOrgConfigGuard,
      },
      {
        path: 'deploy_detection/confirm',
        component: DeployDetectionConfirmPage,
        props: true,
        name: 'editCodeDeploymentDeployDetectionConfirm',
        meta: { hideTopNav: true },
        beforeEnter: canChangeOrgConfigGuard,
      },
    ],
  },
  {
    path: '/:orgSlug/search',
    component: SearchPage,
    name: 'search',
    props: route => ({
      orgSlug: route.params.orgSlug,
      query: route.query,
    }),
  },
  {
    path: '/:orgSlug/trends',
    component: TrendsPage,
    name: 'trends',
    props: true,
    redirect: to => ({
      name: 'trendsBreakdown',
      params: {
        orgSlug: to.params.orgSlug,
        metricType: MetricType.Frequency.toLowerCase(),
      },
    }),
    children: [
      {
        path: ':metricType',
        component: TrendsBreakdownPage,
        name: 'trendsBreakdown',
        props: route => ({
          metricType: route.params.metricType.toString().toUpperCase(),
        }),
      },
    ],
  },
  {
    path: '/:orgSlug/compare',
    component: ComparisonPage,
    name: 'comparison',
    props: true,
    beforeEnter: comparePageGuard,
  },
  {
    path: '/:orgSlug/team_wip',
    component: TeamWIPPage,
    name: 'teamWIP',
    props: true,
  },
  {
    path: '/:orgSlug/team/:teamSlug/goals',
    component: TeamGoalsPage,
    name: 'teamGoals',
    props: true,
  },
  {
    path: '/:orgSlug/team-metrics',
    component: TeamMetricsPage,
    name: 'teamMetrics',
    redirect: () => ({ name: 'teamMetricsFrequency' }),
    props: true,
    children: [
      {
        path: 'frequency',
        component: DeployFrequencyChart,
        name: 'teamMetricsFrequency',
        props: true,
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'lead_time',
        component: LeadTimeChart,
        name: 'teamMetricsLeadTime',
        props: true,
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'failure_rate',
        component: FailureRateChart,
        name: 'teamMetricsFailureRate',
        props: true,
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'mttr',
        component: MTTRChart,
        name: 'teamMetricsMTTR',
        props: true,
        meta: { preserveScroll: true, fastLoad: true },
      },
    ],
  },
  {
    path: '/organization/:orgSlug',
    component: OrganizationSettingsPage,
    name: 'organizationSettings',
    props: true,
    children: [
      {
        path: 'manage',
        component: OrganizationDetails,
        name: 'orgDetails',
        props: true,
      },
      {
        path: 'authentication',
        component: OrganizationAuthentication,
        name: 'authentication',
        props: true,
      },
      {
        path: 'access-tokens',
        component: OrganizationAccessTokens,
        name: 'accessTokens',
        props: true,
      },
      {
        path: 'members',
        component: OrganizationMembers,
        name: 'orgMembers',
        props: true,
        redirect: to => ({
          name: 'orgMembersList',
          params: { ...to.params, userType: UsersType.Members.toLowerCase() },
        }),
        children: [
          {
            path: 'list/:userType',
            component: OrganizationMembersList,
            name: 'orgMembersList',
            props: true,
          },
          {
            path: 'invite',
            component: OrganizationMembersInvite,
            name: 'orgMembersInvite',
            props: true,
          },
        ],
      },
      {
        path: 'teams',
        component: OrganizationTeams,
        name: 'orgTeams',
        props: true,
      },
      {
        path: 'projects',
        component: OrganizationProjects,
        name: 'orgProjects',
        props: true,
      },
      {
        path: 'labels',
        component: OrganizationLabels,
        name: 'orgLabels',
        props: true,
      },
    ],
  },
  {
    path: '/organization/:orgSlug/broken-configurations',
    component: BrokenConfigurationsPage,
    name: 'brokenConfigurations',
    props: true,
    beforeEnter: canChangeOrgConfigGuard,
  },
  {
    path: '/organization/:orgSlug/members/:userId/identities',
    component: OrganizationMembersExternalUserManagement,
    name: 'orgMembersExternalUserManagement',
    props: true,
    meta: { hideTopNav: true },
    beforeEnter: orgMembersExternalUserManagementPageGuard,
  },
  {
    path: '/organization/:orgSlug/integrations/:providerCategory?',
    component: IntegrationsPage,
    name: 'integrations',
    props: true,
    meta: { noProjectOrEnvironment: true },
  },
  {
    path: '/organization/:orgSlug/teams/members/:teamSlug',
    component: TeamMembersPage,
    name: 'teamMembers',
    props: true,
    meta: { hideTopNav: true },
  },
  {
    path: '/organization/:orgSlug/teams/details/:teamSlug?',
    component: TeamDetailsPage,
    name: 'teamDetails',
    props: true,
    meta: { hideTopNav: true },
  },
  {
    path: '/:orgSlug/:projectSlug',
    component: ProjectDashboardPage,
    name: 'projectDashboard',
    props: true,
  },
  {
    path: '/:orgSlug/:projectSlug/goals',
    component: ProjectGoalsPage,
    name: 'projectGoals',
    props: true,
  },
  {
    path: '/:orgSlug/:projectSlug/metrics',
    component: ProjectMetricsPage,
    name: 'projectMetrics',
    redirect: () => ({ name: MetricType.Frequency }),
    props: true,
    children: [
      {
        path: 'frequency',
        component: DeployFrequencyChart,
        name: MetricType.Frequency,
        props: true,
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'lead_time',
        component: LeadTimeChart,
        name: MetricType.LeadTime,
        props: true,
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'failure_rate',
        component: FailureRateChart,
        name: MetricType.FailureRate,
        props: true,
        meta: { preserveScroll: true, fastLoad: true },
      },
      {
        path: 'mttr',
        component: MTTRChart,
        name: MetricType.Mttr,
        props: true,
        meta: { preserveScroll: true, fastLoad: true },
      },
    ],
  },
  {
    path: '/:orgSlug/:projectSlug/metrics/insight/details',
    component: ProjectMetricsInsightPage,
    name: 'projectMetricsInsight',
    props: true,
    meta: { hideTopNav: true },
    beforeEnter: (to): boolean => {
      if (!to.query.startDate || !to.query.endDate) {
        console.error('`startDate` and `endDate` are required query params!')
        return false
      }
      return true
    },
  },
  {
    path: '/:orgSlug/:projectSlug/add_impact_source/provider',
    component: ImpactSourceFormPage,
    name: 'impactSourceForm',
    props: true,
    meta: { hideTopNav: true },
  },
  {
    path: '/:orgSlug/:projectSlug/add_impact_source/:rawCategory/:rawProvider',
    component: CreateEditImpactSourceForm,
    name: 'addImpactSource',
    props: true,
    meta: { hideTopNav: true },
  },
  {
    path: '/:orgSlug/:projectSlug/edit_impact_source/:slug',
    component: CreateEditImpactSourceForm,
    name: 'editImpactSource',
    props: true,
    meta: { hideTopNav: true },
  },
  {
    path: '/:orgSlug/:projectSlug/wip',
    component: ProjectWIPPage,
    name: 'projectWIP',
    props: true,
  },
  {
    path: '/:orgSlug/:projectSlug',
    component: ProjectSettingsPage,
    name: 'projectSettings',
    props: true,
    children: [
      {
        path: '_details',
        component: ProjectDetails,
        name: 'projectSettingsDetails',
        props: true,
      },
      {
        path: '_slack_config',
        component: ProjectSlackNotifications,
        name: 'projectSettingsSlackNotifications',
        props: true,
      },
      {
        path: '_environments',
        component: ProjectEnvironments,
        name: 'projectSettingsEnvironments',
        props: true,
      },
      {
        path: '_feature_flags',
        component: ProjectFeatureFlags,
        name: 'projectSettingsFeatureFlags',
        props: true,
      },
      {
        path: '_code_deployments',
        component: ProjectCodeDeployments,
        name: 'projectSettingsCodeDeployments',
        props: true,
      },
      {
        path: '_impacts',
        component: ProjectImpactSources,
        name: 'projectSettingsImpactSources',
        props: true,
      },
      {
        path: '_labels',
        component: ProjectLabels,
        name: 'projectSettingsLabels',
        props: true,
      },
    ],
  },
  {
    path: '/:orgSlug',
    component: OrgStatusPage,
    name: 'orgStatus',
    props: true,
  },
  {
    path: '/:orgSlug/create_project',
    component: ProjectCreatePage,
    name: 'projectCreate',
    props: true,
    meta: { noProjectOrEnvironment: true, hideTopNav: true },
    beforeEnter: canChangeOrgConfigGuard,
  },
  {
    path: '/:orgSlug/_breakdown_issues',
    component: BreakdownIssuesPage,
    name: 'breakdownIssues',
    props: true,
    meta: { hideTopNav: true },
  },
  // Automations routes
  {
    path: '/:orgSlug/automations',
    component: RouterView,
    name: 'automations',
    props: true,
    meta: { noProjectOrEnvironment: true, hideLeftNav: true },
    redirect: () => ({ name: 'toolsListing' }),
    children: [
      {
        path: '',
        component: MarketplacePage,
        name: 'toolsListing',
      },
      {
        path: 'pr-checks/:prId',
        component: PullRequestChecksPage,
        name: 'prChecks',
        props: true,
        meta: { noProjectOrEnvironment: true, hideTopNav: true },
      },
      {
        path: ':toolSlug',
        component: MarketplaceToolDetails,
        name: 'marketplaceToolDetails',
        props: true,
      },
      {
        path: ':toolSlug/install',
        component: ToolInstall,
        name: 'toolInstall',
        props: true,
        meta: { noProjectOrEnvironment: true, hideTopNav: true },
        beforeEnter: async () => isUserLoggedInGuard(client),
      },
    ],
  },
  {
    path: '/:orgSlug/:projectSlug/automations',
    component: RouterView,
    name: 'projectAutomations',
    props: true,
    redirect: () => ({ name: 'projectAutomationsListing' }),
    children: [
      {
        path: '',
        component: ProjectAutomationsPage,
        props: true,
        name: 'projectAutomationsListing',
      },
      {
        path: ':toolSlug',
        component: ProjectToolDetails,
        name: 'projectToolDetails',
        props: true,
      },
      {
        path: ':toolSlug/edit',
        component: ProjectToolEdit,
        name: 'projectToolEdit',
        props: true,
        meta: { hideTopNav: true },
        beforeEnter: async () => isUserLoggedInGuard(client),
      },
    ],
  },
  {
    path: '/:orgSlug/team/:teamSlug/automations',
    component: RouterView,
    name: 'teamAutomations',
    props: true,
    redirect: () => ({ name: 'teamAutomationsListing' }),
    children: [
      {
        path: '',
        component: TeamAutomationsPage,
        name: 'teamAutomationsListing',
        props: true,
      },
      {
        path: ':toolSlug',
        component: TeamToolDetails,
        name: 'teamToolDetails',
        props: true,
      },
      {
        path: ':toolSlug/edit',
        component: TeamToolEdit,
        name: 'teamToolEdit',
        props: true,
        meta: { hideTopNav: true },
        beforeEnter: async () => isUserLoggedInGuard(client),
      },
    ],
  },
  // 404 catch all
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFound,
    meta: { hideTopNav: true },
  },
]
