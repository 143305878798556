<script lang="ts" setup>
import { computed } from 'vue'

import BaseSpinner from './BaseSpinner.vue'

const props = withDefaults(
  defineProps<{
    headerColorClass?: string[]
    smallHeader?: boolean
    loading?: boolean
    wrapperClass?: string
  }>(),
  {
    headerColorClass: () => [],
    wrapperClass: 'tw-py-4 tw-px-5',
  },
)

const headerYPadding = computed(() =>
  props.smallHeader ? ['tw-py-2'] : ['tw-py-3'],
)
</script>

<template>
  <div
    class="tw-relative tw-my-4 tw-rounded-lg tw-bg-white tw-shadow-md dark:tw-bg-gray-800 dark:tw-text-gray-200"
  >
    <div
      v-if="loading"
      class="tw-absolute tw-z-10 tw-flex tw-size-full tw-items-center tw-justify-center tw-rounded-lg tw-bg-white tw-opacity-50 dark:tw-bg-gray-800"
    >
      <BaseSpinner size="medium" />
    </div>
    <header
      v-if="$slots.header"
      class="tw-rounded-t-lg tw-px-5"
      :class="headerColorClass.concat(headerYPadding)"
    >
      <slot name="header" />
    </header>
    <div :class="wrapperClass">
      <slot />
    </div>
  </div>
</template>
