<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router'

import type { ButtonVariant } from '~/types/invented'
import { computed } from 'vue'
import BaseSpinner from './BaseSpinner.vue'

// TODO: Remove hacky dropdown size once our 'medium' button is a perfect match
// for our dropdowns (height wise).
type ButtonSize = 'small' | 'compact' | 'dropdown' | 'medium' | 'large'

const props = withDefaults(
  defineProps<{
    size?: ButtonSize
    variant?: ButtonVariant
    slotClasses?: string
    disabled?: boolean
    href?: string
    loading?: boolean
    flatLeft?: boolean
    flatRight?: boolean
    route?: RouteLocationRaw | null
  }>(),
  {
    size: 'medium',
    variant: 'regular',
    slotClasses: '',
    href: '',
    route: null,
  },
)

const buttonClasses = computed(() => {
  const sizeClasses = {
    small: ['tw-text-xs', 'tw-py-1', 'tw-px-2'],
    compact: ['tw-py-1.5', 'tw-px-2'],
    dropdown: ['tw-py-[5px]', 'tw-pl-2', 'tw-pr-4'],
    medium: ['tw-py-2', 'tw-px-4'],
    large: ['tw-text-base', 'tw-py-2', 'tw-px-4'],
  }[props.size]

  const variantClasses = {
    regular: [
      'tw-text-white',
      'tw-bg-blue-700',
      'focus:tw-shadow-lg',
      'hover:tw-bg-blue-800',
    ],
    secondary: [
      'tw-text-white',
      'tw-bg-gray-400',
      'focus:tw-shadow-lg',
      'hover:tw-bg-gray-700',
    ],
    delete: ['tw-text-white', 'tw-bg-red-500', 'focus:tw-shadow-lg'],
    link: ['tw-text-gray-500', 'tw-bg-transparent', 'hover:tw-text-blue-700'],
    outline: [
      'tw-border',
      'tw-border-gray-300 dark:tw-border-gray-400',
      'tw-text-gray-700 dark:tw-text-gray-200',
      'tw-bg-transparent',
      'hover:tw-bg-gray-100 dark:hover:tw-bg-gray-800',
      'focus:tw-shadow-lg',
    ],
    shadow: [
      'tw-shadow-md',
      'tw-font-semibold',
      'tw-text-gray-500',
      'tw-bg-white',
      'focus:tw-shadow-lg',
    ],
    light: [
      'tw-bg-blue-600',
      'tw-bg-opacity-5',
      'tw-text-blue-600',
      'focus:tw-shadow-lg',
    ],
  }[props.variant]

  const roundedClasses = ['tw-rounded-l-md', 'tw-rounded-r-md']
  if (props.flatLeft)
    roundedClasses.shift()
  if (props.flatRight)
    roundedClasses.pop()

  const disabledClasses = props.disabled
    ? ['tw-pointer-events-none', 'tw-opacity-50']
    : ['tw-cursor-pointer']

  return [
    ...sizeClasses,
    ...variantClasses,
    ...roundedClasses,
    ...disabledClasses,
  ]
})

const element = computed(() => {
  if (props.route)
    return 'RouterLink'
  else if (props.href)
    return 'a'
  else
    return 'button'
})

const linkProps = computed(() => {
  if (props.route)
    return { to: props.route }
  else if (props.href)
    return { href: props.href }
  else
    return {}
})
</script>

<template>
  <component
    :is="element"
    v-bind="linkProps"
    class="tw-relative tw-inline-block tw-select-none tw-p-0 tw-text-center tw-outline-none"
    :class="buttonClasses"
    :tabindex="disabled ? -1 : 0"
    :disabled="disabled || loading"
  >
    <div
      v-if="loading"
      class="tw-absolute tw-left-0 tw-top-0 tw-flex tw-size-full tw-items-center tw-justify-center"
    >
      <BaseSpinner v-if="loading" />
    </div>
    <div
      :class="loading ? `${slotClasses} tw-text-transparent` : slotClasses"
      class="tw-truncate"
    >
      <slot />
    </div>
  </component>
</template>
