<script lang="ts" setup>
import Multiselect from '@vueform/multiselect'
import { omit } from 'lodash-es'
import { computed, ref, useAttrs } from 'vue'

import FormFieldWrapper from './FormFieldWrapper.vue'

defineOptions({ inheritAttrs: false })

const props = withDefaults(
  // Multiselect props - https://github.com/vueform/multiselect#configuration
  defineProps<{
    label?: string
    invalid?: boolean
    errors?: string[]
    transparent?: boolean
    heightClass?: string
    labelProp?: string
    disabled?: boolean
    placeholder?: string
    searchable?: boolean
    // eslint-disable-next-line ts/no-unsafe-function-type
    options?: object | Function | any[]
    groups?: boolean
    trackBy?: string | string[]
  }>(),
  {
    label: '',
    errors: () => [],
    heightClass: 'tw-h-8',
    labelProp: 'label',
  },
)

const attrs = useAttrs()

const multiselectInstance = ref<InstanceType<typeof Multiselect>>()

const modelValue = defineModel<string | any[] | boolean | null | undefined>()
defineExpose({ multiselectInstance })

function labelFormatter(value: Record<'value' | 'label', string>[]): string {
  return value.map(value => value.label).join(', ')
}

const placeholder = ref(props.placeholder)
function togglePlaceholder(open: boolean) {
  if (!props.searchable)
    return
  placeholder.value = open ? 'Search' : props.placeholder
}

const mergedTrackBy = computed(() => {
  const track = !props.groups || !Array.isArray(props.options) ? ['label'] : ['searchValue']
  if (Array.isArray(props.trackBy)) {
    return [...track, ...props.trackBy]
  }
  else if (props.trackBy) {
    return [...track, props.trackBy]
  }
  return track
})
const transformedOptions = computed(() => {
  if (!props.options) {
    return []
  }

  if (!props.groups || !Array.isArray(props.options)) {
    return props.options
  }

  return props.options.map((group) => {
    return {
      label: group.label,
      options: group.options.map((option: any) => {
        return {
          value: option.value,
          label: option.label,
          searchValue: `${group.label} ${option.label}`,
        }
      }),
    }
  })
})

const multiselectClasses = computed(() => {
  const background = props.transparent
    ? 'tw-bg-transparent'
    : props.disabled
      ? 'tw-bg-gray-100 dark:tw-bg-gray-800'
      : 'tw-bg-white dark:tw-bg-gray-700 dark:tw-text-gray-200'
  const activeBorderColor = props.invalid
    ? 'tw-border-red-200'
    : 'tw-border-blue-200'
  const inactiveBorderColor = props.invalid
    ? 'tw-border-red-200'
    : 'tw-border-gray-300'
  return {
    container: `${
      props.heightClass
    } tw-relative tw-mx-auto tw-w-full tw-flex tw-items-center tw-justify-end tw-box-border ${
      !props.disabled ? 'tw-cursor-pointer' : ''
    } tw-border tw-border-solid ${inactiveBorderColor} tw-rounded-md ${background} tw-leading-snug tw-outline-none dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-text-gray-200`,
    containerDisabled: 'tw-cursor-default tw-bg-gray-100 tw-text-gray-500',
    containerOpen: 'tw-rounded-b-none',
    containerOpenTop: 'tw-rounded-t-none',
    containerActive: `container-active ${activeBorderColor}`,
    wrapper: `tw-h-full tw-relative tw-mx-auto tw-w-full tw-flex tw-items-center tw-justify-end tw-box-border ${
      !props.disabled ? 'tw-cursor-pointer' : ''
    } tw-outline-none`,
    singleLabel: `tw-flex tw-items-center tw-h-full tw-max-w-full tw-absolute tw-left-0 tw-top-0 tw-pointer-events-none tw-bg-transparent tw-leading-snug tw-pl-2 tw-box-border ${
      attrs.canClear ? 'tw-pr-16' : 'tw-pr-2'
    }`,
    singleLabelText:
      'tw-overflow-ellipsis tw-overflow-hidden tw-block tw-whitespace-nowrap tw-max-w-full',
    multipleLabel:
      'tw-pointer-events-none tw-bg-transparent tw-leading-snug tw-pl-2 tw-truncate tw-mr-auto tw-z-10',
    search: `tw-w-full tw-h-full tw-absolute tw-inset-0 tw-outline-none focus:tw-ring-0 tw-appearance-none tw-box-border tw-border-0 tw-text-xs tw-font-sans ${background} tw-rounded tw-pl-2`,
    tags: 'tw-flex-grow tw-flex-shrink tw-flex tw-flex-wrap tw-items-center tw-mt-1 tw-pl-2',
    tag: 'tw-text-xs tw-font-semibold tw-mr-1 tw-mb-1 tw-flex tw-items-center tw-whitespace-nowrap',
    tagDisabled: 'tw-pr-2 tw-opacity-50',
    tagRemove:
      'tw-flex tw-items-center tw-justify-center tw-p-1 tw-mx-0.5 tw-rounded-sm hover:tw-bg-opacity-10 tw-group',
    tagRemoveIcon:
      'tw-bg-multiselect-remove tw-bg-center tw-bg-no-repeat tw-opacity-30 tw-inline-block tw-w-3 tw-h-3 group-hover:tw-opacity-60 dark:tw-invert',
    tagsSearchWrapper:
      'tw-inline-block tw-relative tw-mx-1 tw-mb-1 tw-flex-grow tw-flex-shrink tw-h-full',
    tagsSearch:
      'tw-absolute tw-inset-0 tw-border-0 tw-outline-none focus:tw-ring-0 tw-appearance-none tw-p-0 tw-text-xs tw-font-sans tw-box-border w-full dark:tw-bg-gray-700 dark:tw-text-white',
    tagsSearchCopy:
      'tw-invisible tw-whitespace-pre-wrap tw-inline-block tw-h-px',
    placeholder:
      'tw-flex tw-items-center tw-h-full tw-absolute tw-left-0 tw-top-0 tw-pointer-events-none tw-bg-transparent tw-leading-snug tw-pl-2 tw-text-gray-500 tw-text-sm',
    caret:
      'tw-bg-multiselect-caret tw-bg-center tw-bg-no-repeat tw-w-3.5 tw-h-3.5 tw-py-px tw-box-content tw-mr-4 tw-relative tw-z-10 tw-flex-shrink-0 tw-flex-grow-0 tw-transition-transform tw-transform tw-pointer-events-none dark:tw-bg-multiselect-caret-white',
    caretOpen:
      'tw-rotate-180 tw-pointer-events-auto dark:tw-bg-multiselect-caret-white',
    clear:
      'tw-pr-1 tw-relative tw-z-10 tw-opacity-40 tw-transition tw-duration-300 tw-flex-shrink-0 tw-flex-grow-0 tw-flex hover:tw-opacity-80',
    clearIcon:
      'tw-bg-multiselect-remove dark:tw-bg-multiselect-remove-white tw-bg-center tw-bg-no-repeat tw-w-2.5 tw-h-4 tw-py-px tw-box-content tw-inline-block',
    spinner:
      'tw-bg-multiselect-spinner tw-bg-center tw-bg-no-repeat tw-w-4 tw-h-4 tw-z-10 tw-mr-3.5 tw-animate-spin tw-flex-shrink-0 tw-flex-grow-0',
    dropdown: `tw-max-h-60 tw-absolute tw--left-px tw--right-px tw-bottom-0 tw-transform tw-translate-y-full tw-border tw-border-solid ${inactiveBorderColor} tw--mt-px tw-overflow-y-auto tw-z-50 tw-bg-white tw-flex tw-flex-col tw-rounded-b dark:tw-border-gray-600 tw-shadow-xl`,
    dropdownTop:
      'tw--translate-y-full tw-top-px tw-bottom-auto tw-flex-col-reverse tw-rounded-b-none tw-rounded-t',
    dropdownHidden: 'tw-hidden',
    options: 'tw-flex tw-flex-col tw-p-0 tw-m-0 tw-list-none',
    optionsTop: 'tw-flex-col-reverse',
    group: 'tw-p-0 tw-m-0',
    groupLabel:
      'tw-flex tw-text-xs tw-box-border tw-items-center tw-justify-start tw-text-left tw-py-1 tw-px-3 tw-font-semibold tw-bg-gray-200 dark:tw-bg-gray-800 tw-cursor-default tw-leading-normal',
    groupLabelPointable: 'tw-cursor-pointer',
    groupLabelPointed: 'tw-bg-gray-400 tw-text-gray-700',
    groupLabelSelected: 'tw-bg-green-600 tw-text-white',
    groupLabelDisabled: 'tw-bg-gray-50 tw-text-gray-400 tw-cursor-not-allowed',
    groupLabelSelectedPointed: 'tw-bg-green-600 tw-text-white tw-opacity-90',
    groupLabelSelectedDisabled:
      'tw-text-green-100 tw-bg-green-600 tw-bg-opacity-50 tw-cursor-not-allowed',
    groupOptions: 'tw-p-0 tw-m-0',
    option:
      'tw-flex tw-items-center tw-justify-start tw-box-border tw-text-left tw-cursor-pointer tw-text-sm tw-font-normal tw-leading-snug tw-py-2 tw-px-3 tw-break-all dark:tw-bg-gray-600',
    optionPointed: 'tw-bg-gray-50 dark:tw-bg-blue-800',
    optionSelected: 'tw-bg-blue-100 tw-font-semibold dark:tw-bg-gray-700',
    optionDisabled: 'tw-text-gray-400 tw-cursor-not-allowed',
    optionSelectedPointed:
      'tw-bg-blue-100 tw-font-semibold tw-bg-gray-50 dark:tw-bg-gray-700',
    optionSelectedDisabled:
      'tw-text-gray-400 tw-bg-blue-100 tw-font-semibold tw-cursor-not-allowed',
    noOptions:
      'tw-py-2 tw-px-3 tw-text-gray-600 tw-bg-white tw-text-left dark:tw-bg-gray-700 dark:tw-text-gray-400',
    noResults:
      'tw-py-2 tw-px-3 tw-text-gray-600 tw-bg-white tw-text-left dark:tw-bg-gray-700 dark:tw-text-gray-400',
    fakeInput:
      'tw-bg-transparent tw-absolute tw-left-0 tw-right-0 tw--bottom-px tw-w-full tw-h-px tw-border-0 tw-p-0 tw-appearance-none tw-outline-none tw-text-transparent',
    assist: 'tw-sr-only',
    spacer: `tw-hidden`,
  }
})
</script>

<template>
  <FormFieldWrapper :class="$attrs.class" :label="label" :errors="errors">
    <Multiselect
      ref="multiselectInstance"
      v-model="modelValue"
      :classes="multiselectClasses"
      :multiple-label="labelFormatter"
      :label="labelProp"
      :disabled="disabled"
      :groups="props.groups"
      :options="transformedOptions"
      :track-by="mergedTrackBy"
      v-bind="omit($attrs, 'class')"
      :placeholder="placeholder"
      :searchable="searchable"
      @open="togglePlaceholder(true)"
      @close="togglePlaceholder(false)"
    />
    <template v-if="$slots.help" #help>
      <slot name="help" />
    </template>
  </FormFieldWrapper>
</template>
