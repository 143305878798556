<script setup lang="ts">
import type { GetToolsQuery } from './_Page.generated'
import { useRouteQuery } from '@vueuse/router'
import { useQuery } from 'villus'
import { computed, ref } from 'vue'

import { useRoute } from 'vue-router'
import BaseButton from '~/components/BaseButton.vue'
import BasePage from '~/components/BasePage.vue'
import BaseSearch from '~/components/BaseSearch.vue'
import BaseSpinner from '~/components/BaseSpinner.vue'
import Dropdown from '~/components/Dropdown.vue'
import PageTitle from '~/components/PageTitle.vue'
import { useSafeSessionStorage } from '~/composables/use-safe-storage'
import getTagsAndToolType from '~/pages/automations/tools/tag-mapping'
import ToolCard from '~/pages/automations/tools/ToolCard.vue'
import ToolCardTags from '~/pages/automations/tools/ToolCardTags.vue'
import ToolGrid from '~/pages/automations/tools/ToolGrid.vue'
import { ensureStringArray, getListOfNodes } from '~/utils/utils'
import {
  GetCustomToolsDocument,
  GetFrontActionToolsDocument,
  GetFrontNotificationToolsDocument,
  GetFrontPrCheckToolsDocument,
  GetFrontWorkflowToolsDocument,
  GetLatestToolsDocument,
  GetRecommendedToolsDocument,
  GetToolsDocument,

} from './_Page.generated'
import MarketplaceLeftNav from './MarketplaceLeftNav.vue'

const searchTerm = useRouteQuery('search', '')
const route = useRoute()

const pageSize = 9
const first = ref(pageSize)
function loadMore() {
  first.value += pageSize
}

const sortOptions = [
  { value: 'default', label: 'Default' },
  { value: 'date', label: 'Date added' },
  { value: 'installs', label: 'Popularity' },
]
const sortMode = useSafeSessionStorage(
  'marketplace-tool-sort-mode',
  sortOptions[0].value,
)
const isSortedByDate = computed(() => sortMode.value === 'date')
const isSorted = computed(() => sortMode.value !== 'default')

const routeHasQueryParams = computed(() => {
  if (route.query.filter === undefined)
    return false
  return Object.values(route.query).filter(Boolean).length !== 0
})

const { data: filteredToolsData, isFetching: isFetchingFilteredTools }
  = useQuery({
    query: GetToolsDocument,
    variables: computed(() => ({
      tags: ensureStringArray(route.query.filter),
      term: searchTerm.value?.toString(),
      first: route.query.filter?.toString() === 'custom' ? 1 : first.value,
      orderByLatest: isSortedByDate.value,
    })),
    paused: () =>
      !routeHasQueryParams.value && !searchTerm.value && !isSorted.value,
  })

function augmentToolsWithMetadata(tools?: GetToolsQuery['tools']) {
  return getListOfNodes(tools).map((tool) => {
    const { tags, toolType, isRecommended } = getTagsAndToolType(tool.tags)
    return {
      ...tool,
      tags,
      toolType,
      isRecommended,
    }
  })
}

const filteredTools = computed(() =>
  augmentToolsWithMetadata(filteredToolsData.value?.tools),
)

// front page sections (no filter, no search)
const { data: latestToolsData, isFetching: isFetchingLatestTools } = useQuery({
  query: GetLatestToolsDocument,
  paused: () => routeHasQueryParams.value,
})
const { data: recommendedToolsData, isFetching: isFetchingRecommendedTools }
  = useQuery({
    query: GetRecommendedToolsDocument,
    paused: () => routeHasQueryParams.value,
    variables: computed(() => ({
      orderByLatest: isSortedByDate.value,
    })),
  })
const { data: prChecksData, isFetching: isFetchingPrChecks } = useQuery({
  query: GetFrontPrCheckToolsDocument,
  paused: () => routeHasQueryParams.value,
  variables: computed(() => ({
    orderByLatest: isSortedByDate.value,
  })),
})
const { data: notificationsData, isFetching: isFetchingNotifications }
  = useQuery({
    query: GetFrontNotificationToolsDocument,
    paused: () => routeHasQueryParams.value,
    variables: computed(() => ({
      orderByLatest: isSortedByDate.value,
    })),
  })
const { data: actionsData, isFetching: isFetchingActions } = useQuery({
  query: GetFrontActionToolsDocument,
  paused: () => routeHasQueryParams.value,
  variables: computed(() => ({
    orderByLatest: isSortedByDate.value,
  })),
})
const { data: workflowsData, isFetching: isFetchingWorkflows } = useQuery({
  query: GetFrontWorkflowToolsDocument,
  paused: () => routeHasQueryParams.value,
  variables: computed(() => ({
    orderByLatest: isSortedByDate.value,
  })),
})
const { data: customToolsData, isFetching: isFetchingCustomTools } = useQuery({
  query: GetCustomToolsDocument,
  paused: () => routeHasQueryParams.value,
  variables: computed(() => ({
    orderByLatest: isSortedByDate.value,
  })),
})

const isFetching = computed(() => {
  if (routeHasQueryParams.value)
    return isFetchingFilteredTools.value

  return [
    isFetchingLatestTools.value,
    isFetchingRecommendedTools.value,
    isFetchingPrChecks.value,
    isFetchingNotifications.value,
    isFetchingActions.value,
    isFetchingWorkflows.value,
    isFetchingCustomTools.value,
  ].some(v => v)
})

const groupMapping = computed(() => [
  {
    condition: !route.query.filter,
    title: 'Latest additions',
    description:
      'Here are the latest automations added to the marketplace. Check back often to see what’s new!',
    data: augmentToolsWithMetadata(latestToolsData.value?.tools),
  },
  {
    condition: !route.query.filter,
    title: 'Recommended for you',
    description:
      'Here are a few of our most popular automations. This is a great place to start exploring how Sleuth automations can help your teams improve today!',
    data: augmentToolsWithMetadata(recommendedToolsData.value?.tools),
  },
  {
    condition: !route.query.filter || route.query.filter === 'pr_check',
    title: 'PR Checks',
    description:
      'PR checks prevent pull requests from being merged until they meet certain requirements. They ensure that changes are fully vetted before they’re merged, streamlining code review and avoiding the need to lock down a repository. Think of them as linters for your pull request process!',
    data:
      route.query.filter === 'pr_check'
        ? filteredTools.value
        : augmentToolsWithMetadata(prChecksData.value?.tools),
    totalCount: prChecksData.value?.tools.totalCount,
    filter: 'pr_check',
  },
  {
    condition: !route.query.filter || route.query.filter === 'notification',
    title: 'Notifications',
    description:
      'Notifications drive awareness and help teams respond quickly by automatically alerting them when Sleuth determines there’s something they need to know.',
    data:
      route.query.filter === 'notification'
        ? filteredTools.value
        : augmentToolsWithMetadata(notificationsData.value?.tools),
    totalCount: notificationsData.value?.tools.totalCount,
    filter: 'notification',
  },
  {
    condition: route.query.filter === 'slack',
    title: 'Notifications - Slack',
    description:
      'Notifications drive awareness and help teams respond quickly by automatically alerting them when Sleuth determines there’s something they need to know.',
    data:
      route.query.filter === 'slack'
        ? filteredTools.value
        : augmentToolsWithMetadata(notificationsData.value?.tools),
    totalCount: notificationsData.value?.tools.totalCount,
    filter: 'slack',
  },
  {
    condition: route.query.filter === 'microsoft_teams',
    title: 'Notifications - Microsoft Teams',
    description:
      'Notifications drive awareness and help teams respond quickly by automatically alerting them when Sleuth determines there’s something they need to know.',
    data:
      route.query.filter === 'microsoft_teams'
        ? filteredTools.value
        : augmentToolsWithMetadata(notificationsData.value?.tools),
    totalCount: notificationsData.value?.tools.totalCount,
    filter: 'microsoft_teams',
  },
  {
    condition: !route.query.filter || route.query.filter === 'action',
    title: 'Actions',
    description:
      'Actions are simple but powerful automations that perform one specific action, like transitioning an issue or commenting on a pull request.',
    data:
      route.query.filter === 'action'
        ? filteredTools.value
        : augmentToolsWithMetadata(actionsData.value?.tools),
    totalCount: actionsData.value?.tools.totalCount,
    filter: 'action',
  },
  {
    condition: !route.query.filter || route.query.filter === 'workflow',
    title: 'Workflows',
    description:
      'Workflows provide advanced automations that can evaluate conditions and perform actions across multiple integrated tools. Think of these as your automation “power tools” that can drive significant impacts to your software delivery process.',
    data:
      route.query.filter === 'workflow'
        ? filteredTools.value
        : augmentToolsWithMetadata(workflowsData.value?.tools),
    totalCount: workflowsData.value?.tools.totalCount,
    filter: 'workflow',
  },
  {
    condition: !route.query.filter || route.query.filter === 'custom',
    title: 'Custom',
    description: `Custom automations are Sleuth's the most powerful automation type because they let you realize whatever you can imagine by pulling together the many conditions and actions supported in Sleuth's YAML-based <a href="https://help.sleuth.io/sleuth-automations/actions" target="_blank">Custom Automations framework</a>.`,
    data:
      route.query.filter === 'custom'
        ? filteredTools.value
        : augmentToolsWithMetadata(customToolsData.value?.tools),
    totalCount: customToolsData.value?.tools.totalCount,
    filter: 'custom',
  },
  {
    condition: route.query.filter === 'pull_requests',
    title: 'Pull requests',
    description:
      'A collection of PR checks, notifications, actions, and workflows focused on driving efficiency throughout your pull request process.',
    data: filteredTools.value,
  },
  {
    condition: route.query.filter === 'incidents',
    title: 'Incidents',
    description:
      'A collection of PR checks, notifications, actions, and workflows designed to help teams respond more efficiently and effectively to incidents.',
    data: filteredTools.value,
  },
  {
    condition: route.query.filter === 'ci_cd',
    title: 'CI/CD',
    description:
      'A collection of PR checks, notifications, actions, and workflows designed to improve your build and deploy processes.',
    data: filteredTools.value,
  },
  {
    condition: route.query.filter === 'issues',
    title: 'Issues',
    description:
      'A collection of PR checks, notifications, actions, and workflows designed to improve your ticketing / issue management process.',
    data: filteredTools.value,
  },
  {
    condition: route.query.filter === 'frequency',
    title: 'Frequency',
    description:
      'A collection of PR checks, notifications, actions, and workflows designed to help teams increase their deploy frequency.',
    data: filteredTools.value,
  },
  {
    condition: route.query.filter === 'batch_size',
    title: 'Batch size',
    description:
      'A collection of PR checks, notifications, actions, and workflows designed to help teams work in smaller batch sizes.',
    data: filteredTools.value,
  },
  {
    condition: route.query.filter === 'lead_time',
    title: 'Lead time',
    description:
      'A collection of PR checks, notifications, actions, and workflows all designed to help rein in lead time.',
    data: filteredTools.value,
  },
  {
    condition: route.query.filter === 'coding_time',
    title: 'Coding time',
    description:
      'A collection of PR checks, notifications, actions, and workflows all designed to help teams rein in coding time.',
    data: filteredTools.value,
  },
  {
    condition: route.query.filter === 'review_lag',
    title: 'Review lag',
    description:
      'A collection of PR checks, notifications, actions, and workflows all designed to help teams rein in review lag.',
    data: filteredTools.value,
  },
  {
    condition: route.query.filter === 'review_time',
    title: 'Review time',
    description:
      'A collection of PR checks, notifications, actions, and workflows all designed to help teams rein in review time.',
    data: filteredTools.value,
  },
  {
    condition: route.query.filter === 'deploy_time',
    title: 'Deploy time',
    description:
      'A collection of PR checks, notifications, actions, and workflows all designed to help teams rein in deploy time.',
    data: filteredTools.value,
  },
  {
    condition: route.query.filter === 'failure_rate',
    title: 'Failure rate',
    description:
      'A collection of PR checks, notifications, actions, and workflows all designed to help teams reduce their change failure rate.',
    data: filteredTools.value,
  },
  {
    condition: route.query.filter === 'mttr',
    title: 'MTTR',
    description:
      'A collection of PR checks, notifications, actions, and workflows all designed to help reduce their mean time to recovery.',
    data: filteredTools.value,
  },
])
</script>

<template>
  <BasePage :is-fetching="false" :is-auto-re-fetching="false" :is-done="true">
    <PageTitle part1="Automations" />
    <h1 class="tw-my-0 tw-mb-8 tw-text-2xl dark:tw-text-gray-200">
      Automations Marketplace
    </h1>

    <div
      class="tw-flex tw-flex-col tw-items-start dark:tw-text-gray-200 md:tw-flex-row"
    >
      <MarketplaceLeftNav />
      <div class="tw-flex-1">
        <div class="tw-mb-10 tw-flex">
          <BaseSearch
            v-model:query="searchTerm"
            placeholder="Search"
            width-class="md:tw-w-96 tw-w-full"
            data-element="marketplace-search"
          />

          <div class="tw-ml-5 tw-flex tw-items-center">
            <label class="tw-mr-3">Sort by:</label>
            <Dropdown
              v-model="sortMode"
              :options="sortOptions"
              class="tw-w-32"
              :can-clear="false"
              :can-deselect="false"
            />
          </div>
        </div>

        <!-- Loading -->
        <div
          v-if="isFetching"
          class="tw-flex tw-items-center tw-justify-center tw-py-20"
        >
          <BaseSpinner size="medium" />
        </div>

        <!-- No categories when searching -->
        <template v-else-if="(filteredTools.length && searchTerm) || isSorted">
          <ToolGrid>
            <RouterLink
              v-for="tool in filteredTools"
              :key="tool.slug"
              :to="{
                name: 'marketplaceToolDetails',
                params: {
                  toolSlug: tool.slug,
                },
                query: route.query,
              }"
              class="tw-flex"
              :data-test="`tool-card-${tool.slug.toLowerCase()}`"
            >
              <ToolCard :tool="tool">
                <template #footer>
                  <ToolCardTags :tool="tool" />
                </template>
              </ToolCard>
            </RouterLink>
          </ToolGrid>
          <!-- show more for type page -->
          <div
            v-if="
              isSorted
                && filteredToolsData
                && filteredToolsData.tools.edges.length
                  < filteredToolsData.tools.totalCount
            "
            class="tw-mt-14 tw-flex tw-justify-center"
          >
            <BaseButton variant="outline" @click="loadMore">
              Show more
            </BaseButton>
          </div>
        </template>

        <!-- Tool categories -->
        <template v-else-if="!isFetching && !searchTerm">
          <template v-for="(group, i) in groupMapping">
            <div v-if="group.condition && group.data.length" :key="i">
              <h3>{{ group.title }}</h3>
              <p v-html="group.description" />
              <ToolGrid class="tw-mb-14 tw-mt-6">
                <RouterLink
                  v-for="tool in group.data"
                  :key="tool.slug"
                  :to="{
                    name: 'marketplaceToolDetails',
                    params: {
                      toolSlug: tool.slug,
                    },
                    query: route.query,
                  }"
                  class="tw-flex"
                  :data-test="`tool-card-${tool.slug.toLowerCase()}`"
                >
                  <ToolCard :tool="tool">
                    <template #footer>
                      <ToolCardTags :tool="tool" />
                    </template>
                  </ToolCard>
                </RouterLink>
              </ToolGrid>

              <!-- show more for type page -->
              <div
                v-if="
                  route.query.filter
                    && filteredToolsData
                    && filteredToolsData.tools.edges.length
                      < filteredToolsData.tools.totalCount
                    && group.filter !== 'custom'
                "
                class="tw-flex tw-justify-center"
              >
                <BaseButton variant="outline" @click="loadMore">
                  Show more
                </BaseButton>
              </div>
              <!-- show more for front page; these sections are limited to 9 items via GQL first param -->
              <div
                v-else-if="
                  !route.query.filter
                    && group.filter
                    && group.totalCount
                    && group.data.length < group.totalCount
                    && group.filter !== 'custom'
                "
                class="tw-flex tw-justify-center"
              >
                <BaseButton
                  variant="outline"
                  :route="{
                    name: 'toolsListing',
                    query: { filter: group.filter },
                  }"
                  @click="loadMore"
                >
                  Show more
                </BaseButton>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </BasePage>
</template>
