import type { Ref } from 'vue'

import type { ToolType } from '../types/graphql'
import { computed } from 'vue'

const numFormatter = Intl.NumberFormat('en', {
  notation: 'compact',
  maximumFractionDigits: 1,
})

export function useToolInstallationCount(
  tool: Ref<Pick<ToolType, 'linkedFeatureUrl' | 'installationCount'>>,
) {
  return computed(() => {
    // If the tool is a fake automation, don't show the installation count
    if (!tool.value || tool.value.linkedFeatureUrl)
      return null
    return numFormatter.format(tool.value.installationCount)
  })
}
