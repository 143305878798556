import type { RemovableRef } from '@vueuse/core'
import { useSessionStorage, useStorage } from '@vueuse/core'
import { ref } from 'vue'

import SafeLocalStorage, { SafeSessionStorage } from '~/utils/safe-storage'

export default function useSafeStorage<T>(key: string, defaultValue: T) {
  return SafeLocalStorage.storageAvailable()
    ? useStorage(key, defaultValue)
    : (ref(defaultValue) as unknown as RemovableRef<T>)
}

export function useSafeSessionStorage<T>(key: string, defaultValue: T) {
  return SafeSessionStorage.storageAvailable()
    ? useSessionStorage(key, defaultValue)
    : (ref(defaultValue) as unknown as RemovableRef<T>)
}
